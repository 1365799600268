import React from 'react'

import { msalConfig, loginRequest } from '../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';



import Wrapper from '../components/wrapper'
import AdminPage from '../components/admin-page'

export default () => 
{

    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                <AdminPage></AdminPage>
            </Wrapper>
        </MsalProvider>
    )
}
