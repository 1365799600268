import React, {useState} from 'react'
/* eslint-disable */
import classes from '../styles/admin-page.module.sass' // is used
/* eslint-enable */

import { useMsal } from '../utility/auth/msalContext';
var JSZip = require("jszip");
var FileSaver = require('file-saver');

const AdminPage = () => {

    const [usersData, setUserData] = useState()
    const [userList, setUserList] = useState()
    const [progData, setProgData] = useState()
    const [fetchUser, setFetchUser] = useState()

    const {getUsersFromApi, getUserResultsFromApi} = useMsal();

    async function updateUserList(){
        const usersFromApi = await getUsersFromApi()
        //const newUserdata = JSON.stringify(await getUsersFromApi()).replace(/,/g, '\n')
        setUserData(usersFromApi.join("\n"))
    }

    async function getUserData(user){
        // Accept with @student.liu.se / @liu.se or not
        let user_full = user
        if  (!user.includes("@")){
            user_full = user_full + "@student.liu.se"
        }
        return JSON.stringify(await getUserResultsFromApi(user_full))
    }

    async function fetchData(e) {
        e.preventDefault();

        if (typeof fetchUser !== "undefined"){
            const data = await getUserData(fetchUser)
            setProgData(data)
        }
    }

    async function saveAllUsers(e) {
        e.preventDefault();

        const user_data = await Promise.all(userList.filter(
            user => (user.length > 0)).map(user => [user + ".json", getUserData(user)]))

        let zip = new JSZip();
        user_data.map(data_pair => zip.file(data_pair[0], data_pair[1]))
        zip.generateAsync({type:"blob"}).then(function(content) {
            // Uses FileSaver.js
            FileSaver.saveAs(content, "answers.zip");
        });
    }

    function handleChange(event) {
        setFetchUser(event.target.value);
     }

    function handleUserListChange(event) {
        const userList = event.target.value.split("\n")
        setUserList(userList)
    }

    // Update on page load
    updateUserList()

    return (
        <div className={classes.container}>
            <div className={classes.column}>
                <div>
                    <h2>Registered users:</h2>
                    <textarea value={usersData}>
                    </textarea>
                </div>
                <div>
                    <input type="text" placeholder="liuid123" value={fetchUser} onChange={handleChange}></input>
                    <button onClick={fetchData}>
                        Fetch userdata
                    </button>

                    <br></br>
                    <h2>Userdata:</h2>
                    <textarea value={progData}>

                    </textarea>
                </div>
            </div>
            <div className={classes.column}>
                <h2>Save data for users:</h2>
                <textarea onChange={handleUserListChange}>
                </textarea>
                <br></br>
                <button className={classes.savebtn} onClick={saveAllUsers}>
                    Save All
                </button>
            </div>
        </div>
    )
}

export default AdminPage
